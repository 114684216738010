const FAQ = [
  {
    summary: 'How do I move my photos from another storage service to Asurion Photos?',
    details: (
      <p>
        If the photos are on your device, the app will back them up. Photos and videos
        must be downloaded to your device for the app to find and back them up.
      </p>
    ),
    autoOpen: false,
    categories: ['Backing Up', 'All'],
  },
  {
    summary: 'How long will it take to back up all my photos and videos?',
    details: (
      <p>
        Backup time depends on how many photos and videos are on your device. It may take
        a couple of days to finish your first backup. Speed up your first backup by
        keeping your phone connected to Wi-Fi and charged.
      </p>
    ),
    autoOpen: false,
    categories: ['Backing Up', 'All'],
  },
  {
    summary: 'Do I have to turn on Location Services to back up my photos?',
    details: (
      <p>
        No, Location Services isn't necessary to back up your photos. iOS devices have the
        option to turn on On The Go Backup. On the Go Backup uses Location Services to
        backup your photos when the app is closed. Location Services is only used to save
        your photos when a change is detected in your phone's location. Android devices
        don't need Location Services for On The Go Backup to work. Asurion Photos will
        never track, share, or store your location.
      </p>
    ),
    autoOpen: false,
    categories: ['Backing Up', 'All'],
  },
  {
    summary: 'How do I know my photos are backed up?',
    details: (
      <p>
        Photos without the cloud icon in the lower-right hand corner are backed up. Photos
        with the cloud icon in the lower-right hand corner aren't backed up yet.
      </p>
    ),
    autoOpen: false,
    categories: ['Backing Up', 'All'],
  },
  {
    summary: "Why aren't all my photos backing up?",
    details: (
      <p>
        Photos need to be downloaded to your device for Asurion Photos to find and back
        them up. Photos backed up by iCloud may need to be re-downloaded to your device.
      </p>
    ),
    autoOpen: false,
    categories: ['Backing Up', 'All'],
  },
  {
    summary: "Will my photo quality change once they're backed up?",
    details: (
      <p>
        No, your photos will back up in the same quality as they were taken. They won't
        compress to a smaller size.
      </p>
    ),
    autoOpen: false,
    categories: ['Backing Up', 'All'],
  },
  {
    summary: 'Can I also back up files and documents?',
    details: (
      <p>
        No, Asurion Photos doesn't support file and document back up. However, Asurion
        Home+ customers get unlimited cloud storage with Asurion Drive to back up their
        files and documents.
      </p>
    ),
    autoOpen: false,
    categories: ['Backing Up', 'All'],
  },
];
export default FAQ;
