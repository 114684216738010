
const enablePersona = process.env.REACT_APP_ENABLE_PERSONA_INTEGRATION || false;

const colors = {
  primary: '#8223D2',
  secondary: '#CCEEFB',
  white: '#ffffff',
  dark: '#e6b909',
  black: '#000000',
  blue: '#8223D2',
  grey: '#f8f7f7',
  brownGrey: '#8f8f8f',
  brickRed: '#DB1B1B',
  disabled: '#E6E6EB',
  disabledTextColor: '#60676C'
};

export const fonts = {
  sans: 'ApercuWeb'
};

// Image selector colors
export const selectorColor = colors.primary;
export const selectorBgColor = 'rgba(0, 0, 0, 0.1)';

const theme = {
  palette: {
    primary: {
      main: colors.primary,
      light: colors.white,
    },
    secondary: {
      main: colors.black,
    }
  },
  typography: {
    fontFamily: fonts.sans,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: colors.primary,
        '&:hover': {
          color: colors.primary,
        },
      },
      underlineHover:{
        '&:hover': {
          textDecoration: 'none'
        },
      }
    },
    MuiButton: {
      label: {
        fontFamily: fonts.sans,
      },
      contained: {
        textTransform: 'none',
        boxShadow: 'none',
        borderRadius: '99px',
        width: '100%',
        fontSize: '1rem'
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: JSON.parse(enablePersona) ? colors.black : colors.primary,
          opacity: '0.8'
        },
        '&:disabled, &:disabled:hover': {
          backgroundColor: colors.disbaled,
          color: colors.disbaledTextColor
        },
        color: colors.white,
      },
      textPrimary: {
        color: colors.blue,
        '&:hover': {
          backgroundColor: colors.grey,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          background: colors.brickRed,
          color: colors.white,
          margin: '0px 0px',
          paddingLeft: '4px',
          fontSize: '0.875rem',
          height: '20px',
          lineHeight: '150%',
          boxShadow: '3px 3px 0 rgba(0,0,0,.25)',
          zIndex: 1
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.brownGrey,
        '&$focused': {
          color: colors.brownGrey,
        },
        '&$error': {
          color: colors.brickRed,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderColor: colors.primary,
        '& $notchedOutline': {
          borderRadius: 'unset',
          border: '1px solid',
          borderColor: colors.black,
          boxShadow: '3px 3px 0 rgba(0,0,0,.25)'
        },
        '&:hover $notchedOutline': {
          boxShadow: '3px 3px 0 rgba(0,0,0,.5), inset 0 0 0 1px #000'
        },
        '&$focused $notchedOutline': {
          borderColor: colors.primary,
        },
        '&$error $notchedOutline': {
          borderColor: colors.brickRed,
        },
      },
    },
    MuiFormControl:{
      marginNormal: {
        marginTop: '0px'
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: fonts.sans,
      },
    },
    MuiMobileStepper: {
      dotActive: {
        backgroundColor: colors.black,
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: 4,
        paddingRight: 24,
        paddingBottom: 4,
        paddingLeft: 24,
        "&:last-child": {
          paddingRight: 8
        }
      }
    }
  },
  wallpaper: {
    color: colors.secondary,
  },
  greywallpaper: {
    color: colors.grey,
  },
  customFonts: {
    primary: fonts.sans,
  },
  snackbarBgColor:{
    color: colors.primary
  },
  snackbarTextColor:{
    color: '#fff'
  },
  okayTextColor:{
    color: colors.primary
  },
  spinnerColor:{
    color: colors.primary
  },
  deleteTextColor:{
    color: colors.brickRed
  }
};


export default theme;