const FAQ = [
  {
    summary: 'The app keeps freezing. What do I do?',
    details: (
      <p>
        Uninstall then reinstall the app. Make sure your app is updated to get the best
        functionality and fixes.
      </p>
    ),
    autoOpen: false,
  },
  {
    summary: 'Why are my photos loading so slowly?',
    details: (
      <p>
        If you've just signed in to your account on a new device, the app may take some
        time to load your photos. Keeping the app running in the foreground will help load
        the photos. You can see all your backed up photos at photos.asurion.com anytime to
        make sure they're still there.
      </p>
    ),
    autoOpen: false,
  },
  {
    summary: 'When I edit a photo, does it make a copy or override the original?',
    details: <p>It will make a copy.</p>,
    autoOpen: false,
  },
];
export default FAQ;
