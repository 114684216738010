export const mockApi = process.env.REACT_APP_MOCK_API && {
  authToken: 'https://demo3317325.mockable.io/authToken',
  eventApiV1: '',
  eventApiV2: '',
  webLaunched: 'false',
};

const api = {
  key: process.env.REACT_APP_API_KEY || '1YQ4XR08WVNDWBKU',
  reporting: {
    // key: 'FID5r6gPazXm7uK2x0R2',
    key: 'F4GFAHASFSA6DHEZR',
    auth: 'i4Ud5mTHqdyfrQjiQXMRXxAhp8VHXq6iIIjzgjXtibi9izW5ZTA0zqRQjPgY',
  },
  mixpanel: {
    token: process.env.REACT_APP_MIXPANEL_TOKEN || '664ffe7a8bdf85207bda500ac4251485',
  },
  url: mockApi || {
    authToken:
      process.env.REACT_APP_AUTH_TOKEN_API ||
      'https://memories-qa.asurionpa.com/auth/v1/security/authToken',
    eventApiV1:
      process.env.REACT_APP_EVENTS_V1_API ||
      'https://memories-qa.asurionpa.com/repo/v1/clientReportingEvents',
    eventApiV2:
      process.env.REACT_APP_EVENTS_V2_API ||
      'https://memories-qa.asurionpa.com/repo/v2/clientReportingEvents',
  },
};

export default api;
