import React from 'react';

import { Paper, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import quoteIcon from './assets/quote-opening.png';

const data = [
  {
    title: 'Excellent app',
    desc: 'This app so far has everything I’ve been looking for... I would recommend this app to anyone who takes an abundance of photos!',
  },
  {
    title: 'Not just storage',
    desc: 'Besides storage, the app provides some valuable, intuitive ways to keep your gallery cleaned up.',
  },
  {
    title: 'Wow, this app is awesome!',
    desc: "It quickly backed up all the photos and videos on my device. I won't need to find a drive to store my snaps in the future. Hooray!",
  },
];

const Testimonial = ({ classes, text }) => {
  return (
    <Paper elevation={0} square className={classes.root}>
      <Grid container spacing={16}>
        {data.map(item => (
          <Grid item xs={12} sm={12} md={4} className={classes.item}>
            <>
              <Paper elevation={0} square className={classes.container}>
                <img src={quoteIcon} alt="" className={classes.quote} />
                <Typography variant="h5" className={classes.title}>
                  {item.title}
                </Typography>
                <Typography variant="body1" className={classes.desc}>
                  {item.desc}
                </Typography>
              </Paper>
              <Paper elevation={0} square className={classes.chat}>
                <div className={classes.triangle}></div>
              </Paper>
            </>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

const breakpoint = 'sm';

export const styles = theme => ({
  root: {
    width: '100%',
    padding: '72px 0 102px',
  },
  item: {
    [theme.breakpoints.up(breakpoint)]: {
      padding: '8px !important',
    },
    [theme.breakpoints.down(breakpoint)]: {
      padding: '8px 8px 48px 8px !important',
    },
  },
  container: {
    backgroundColor: '#F0F0F5',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 28,
    textAlign: 'center',
  },
  quote: {
    marginBottom: 24,
  },
  title: {
    marginBottom: 32,
  },
  desc: {
    fontSize: '1rem',
  },
  chat: {
    position: 'relative',
  },
  triangle: {
    position: 'absolute',
    right: 23,
    width: 45,
    height: 30,
    borderLeft: '0 solid transparent',
    borderRight: '45px solid transparent',
    borderTop: '30px solid #F0F0F5',
    [theme.breakpoints.down(breakpoint)]: {
      width: 30,
      height: 30,
      borderRight: '30px solid transparent',
      borderTop: '30px solid #F0F0F5',
    },
  },
});

export default withStyles(styles)(Testimonial);
