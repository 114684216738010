import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Link } from '@material-ui/core';
import brand from '../core/config/brand';

import { publishAnonEvent } from '../services/reportingService';

import footerCarrierLogo from './assets/footer-carrier-logo.svg';

export const Footer = ({ classes }) => {
  const content = brand.content;

  const handleLinkClicked = lname => {
    publishAnonEvent({
      eventType: 'LinkClicked',
      link: lname,
      screen: 'MarketingLandingPage',
    });
  };

  return (
    <Paper square elevation={0} className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            className={classes.serviceroot}
          >
            <Grid item className={classes.carrierLogo}>
              <img
                src={footerCarrierLogo}
                alt="Company branding"
                className={classes.carrierLogoImg}
              />
            </Grid>
            {content.footer.serviceLinks.map((link, i) => (
              <Grid item key={i} className={classes.servicelink}>
                <Typography className={classes.servicelinkText}>
                  <Link
                    href={link.href}
                    onClick={() => handleLinkClicked(link.linkName)}
                    target="_blank"
                  >
                    {link.text}
                  </Link>
                  {/* {' | '} */}
                </Typography>
              </Grid>
            ))}
            <Grid item className={classes.footerNotes}>
              <div className={classes.footerNotesText}>
                Asurion® is the property of Asurion, LLC. All rights reserved. Apple® and
                iPhone® are registered trademarks of Apple Inc. All other trademarks,
                service marks, and product brands that appear on this website are not
                owned by Asurion and are the property of their respective owners. Asurion
                is not affiliated with, sponsored by, or endorsed by Apple or any of the
                respective owners of the other trademarks, service marks and/or product
                brands cited herein.
                <p>© Asurion 1992–2022. All Rights Reserved.</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const styles = theme => ({
  root: {
    textAlign: 'left',
    backgroundColor: '#000',
    width: '100%',
    // padding: 3.5 * theme.spacing.unit,
    padding: theme.spacing(3.5), // material ui 4 changes
    margin: '0px 0px 0px 0px',
    color: '#fff',
    bottom: 0,
    fontFamily: theme.typography.fontFamily,
  },
  carrierLogo: {
    margin: 0,
    padding: 0,
    // marginRight: 3 * theme.spacing.unit,
    marginRight: theme.spacing(3),
  },
  carrierLogoImg: {
    // width: 9.4 * theme.spacing.unit,
    width: theme.spacing(9.4),
  },
  serviceroot: {
    [theme.breakpoints.down('sm')]: {
      // padding: [2 * theme.spacing.unit, 2 * theme.spacing.unit],
      padding: theme.spacing(2, 2),
    },
    fontSize: '1.3rem',
    // padding: [3 * theme.spacing.unit, 7 * theme.spacing.unit],
    padding: theme.spacing(3, 7),
  },
  servicelinkText: {
    fontSize: '1rem',
    color: '#fff',
  },
  servicelink: {
    '& a': {
      textDecoration: 'underline',
      color: '#fff',
      fontSize: '1rem',
      // paddingRight: 0.62 * theme.spacing.unit,
      // paddingLeft: 0.62 * theme.spacing.unit,
      paddingRight: theme.spacing(0.62),
      paddingLeft: theme.spacing(0.62),
    },
    '& :hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
  footerNotes: {
    margin: 0,
    padding: 0,
    // paddingTop: 2.5 * theme.spacing.unit,
    paddingTop: theme.spacing(2.5),
  },
  footerNotesText: {
    color: '#fff',
    fontSize: '0.8rem',
  },
});

export default withStyles(styles)(Footer);
