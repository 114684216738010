import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Header from './Header.js';
import Hero from './Hero.js';
import InfoBar from './InfoBar';
import ProductDetails from '../../migration/ProductDetails';
import Download from './Download';
import Testimonial from '../../migration/Testimonial';
import Faq from './Faq/Faq.js';
import Footer from '../../core/Footer';
import { publishPeoplePropsPreAuth } from '../../services/mixPanelService';
import { publishAnonEvent } from '../../services/reportingService.js';
import { initiateFullsory } from '../../services/fullstory';

const LandingPage = ({ classes }) => {
  useEffect(() => {
    initiateFullsory(); // this should be called first
    publishPeoplePropsPreAuth();
    publishAnonEvent({
      eventType: 'ScreenShown',
      screen: 'MarketingLandingPage',
    });
  }, []);

  return (
    <>
      {/* <FullStory org="o-1X8NQ3-na1" /> */}
      <Header />
      <Paper elevation={0} square>
        <Hero />
        <Paper className={classes.section} elevation={0} square>
          <InfoBar />
        </Paper>
        <Paper className={classes.section} elevation={0} square>
          <Paper elevation={0} square className={classes.grayContainer}>
            <ProductDetails />
          </Paper>
        </Paper>
        <Paper className={classes.section} elevation={0} square>
          <Paper elevation={0} square className={classes.container}>
            <Download text="Download the app to get started" />
          </Paper>
        </Paper>
        <Paper className={classes.section} elevation={0} square>
          <Paper elevation={0} square className={classes.container}>
            <Paper className={classes.testimonialContainer} elevation={0} square>
              <Testimonial />
            </Paper>
          </Paper>
        </Paper>
      </Paper>
      <Faq />
      <Footer></Footer>
    </>
  );
};

export const styles = theme => ({
  section: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
  },
  grayContainer: {
    width: '100%',
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(255, 255, 255, 0) 45.45%)',
  },
  testimonialContainer: {
    margin: '0 24px',
  },
});

export default withStyles(styles)(LandingPage);
