const FAQ = [
  {
    summary: 'Do I qualify for unlimited storage?',
    details: (
      <p>
        Unlimited photo and video storage is available for Asurion protection plan
        subscribers only (carriers include Home+, Cox Mobile, US Cellular, Telus, Asurion
        PhoneCare).
      </p>
    ),
    autoOpen: false,
    categories: ['General', 'All'],
  },
  {
    summary: 'What do I get for free?',
    details: (
      <p>The free tier of Asurion Photos includes 5GB of photo and video storage.</p>
    ),
    autoOpen: false,
    categories: ['General', 'All'],
  },
  {
    summary: 'What makes Asurion Photos different from other photo storage services?',
    details: (
      <>
        <p>
          Most cloud photo storage services lower the resolution of your photos and videos
          before backing them up. Asurion Photos never compresses your files. Photos and
          videos will always back up in their original high quality.{' '}
        </p>
        <p>
          Asurion Photos will also help you clean up your device storage and photo
          gallery. Optimization tools make it easy to eliminate blurry, duplicate, and
          unwanted photos on your device and in the cloud.{' '}
        </p>
      </>
    ),
    autoOpen: false,
    categories: ['General', 'All'],
  },
  {
    summary: 'Is there a size limit for files that can be backed up?',
    details: 'Asurion Photos supports files up to 20GB.',
    autoOpen: false,
    categories: ['General', 'All'],
  },
  {
    summary: 'Can I view photos on my computer, too?',
    details: (
      <>
        <p>
          Yes, you can view your backed up photos on your computer by signing in to your
          account at{' '}
          <a href="https://photos.asurion.com/" target="_blank" rel="noreferrer">
            photos.asurion.com
          </a>
          .{' '}
        </p>
      </>
    ),
    autoOpen: false,
    categories: ['General', 'All'],
  },
  {
    summary: 'Can I share my photos easily within the app?',
    details: 'Yes, you can share your photos directly with others from the app.',
    autoOpen: false,
    categories: ['General', 'All'],
  },
];

export default FAQ;
