const CheckIcon = props => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2 12.6429L8.81818 19.5L22 4.5" strokeWidth="5" />
    </svg>
  );
};
export default CheckIcon;
