import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { withStyles, createTheme, CssBaseline } from '@material-ui/core';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';

import landingPage from '../welcome/LandingPage/LandingPage';

import brand from './config/brand';
import theme from './config/theme';
import browserHistory from './config/browserHistory';
import './assets/font.css';

const muiTheme = createTheme({
  ...theme,
});

export const App = ({ auth }) => (
  <>
    <StylesProvider>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Router history={browserHistory}>
          <Switch>
            <Route path="/" component={landingPage} />
          </Switch>
        </Router>
      </ThemeProvider>
    </StylesProvider>
  </>
);

export const styles = theme => ({
  '@font-face': brand.fonts,
});

export default withStyles(styles)(App);
