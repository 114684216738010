const FAQ = [
  {
    summary: 'How do I know my photos are secure with Asurion Photos?',
    details: (
      <p>
        Asurion Photos protects your photos with bank-level encryption. Your info is never
        sold or shared to third parties. You'll never see intrusive third-party
        advertising in the app.
      </p>
    ),
    autoOpen: false,
  },
  {
    summary: 'Who can see my photos?',
    details: (
      <p>
        Only you (the account owner) can see your photos. You can also add an extra layer
        of security by setting a passcode or using Face ID when opening the Asurion Photos
        app.
      </p>
    ),
    autoOpen: false,
  },
  {
    summary: "I want to update my account's email or phone number. How do I do this?",
    details: (
      <p>
        To update your phone number, go to the Account tab in the app to change it. To
        update your email address, email our support email at{' '}
        <a href="mailto:photos-support@asurion.com">photos-support@asurion.com</a>
      </p>
    ),
    autoOpen: false,
  },
  {
    summary: 'My storage amount is incorrect in the app. How do I fix this?',
    details: (
      <p>
        Make sure the phone number or email address in the Account tab is the one that has
        your Asurion protection plan associated with it. Reach out to support if it is
        incorrect or you're still not getting the correct amount of storage.
      </p>
    ),
    autoOpen: false,
  },
  {
    summary: 'What happens to my photos if I leave my Asurion protection program?',
    categories: ['Account + Security', 'All'],
    details: (
      <p>
        <p>
          You'll keep your Asurion Photos account, but it'll switch to the free tier. Your
          photos will stay safely in the app, but you can't back up more photos if you
          have reached the storage limit.
        </p>
        <p>
          If you'd like to download your photo gallery, you can request an archive of it
          at <a href="http://photos.asurion.com/">photos-support@asurion.com</a>
        </p>
      </p>
    ),
    autoOpen: false,
  },
  {
    summary: 'Does Asurion Photos have customer support?',
    details: (
      <p>
        Yes, the Asurion Photos customer support team can be reached by emailing{' '}
        <a href="mailto:photos-support@asurion.com">photos-support@asurion.com</a>. You
        can start an email directly in the app on the Account tab. Mobility plan customers
        can also chat or call with an expert anytime in the Asurion app.
      </p>
    ),
    autoOpen: false,
  },
];
export default FAQ;
