import React, { useCallback, useState } from 'react';
import {
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as ExpandMoreIcon } from '../../assets/expandmore.svg';
import GeneralQuestions from './Categories/General.js';
import BackingUpQuestions from './Categories/BackingUp.js';
import AccountPlusSecurityQuestions from './Categories/AccountAndSecurity.js';
import TroubleshootingQuestions from './Categories/Troubleshooting.js';
import { publishAnonEvent } from '../../../services/reportingService';

const allQuestions = GeneralQuestions.concat(
  BackingUpQuestions,
  AccountPlusSecurityQuestions,
  TroubleshootingQuestions
);
const Faq = ({ classes, history }) => {
  const [categorySelected, setCategorySelected] = useState('GeneralFAQ');
  const select = category => {
    setCategorySelected(category);
    reportSelection(category);
    deselectAll();
    highlightButton(category + 'Button');
  };
  const deselect = id => {
    document.getElementById(id).className = classes.button;
  };
  const deselectAll = () => {
    deselect('GeneralFAQButton');
    deselect('BackingUpFAQButton');
    deselect('AccountPlusSecurityFAQButton');
    deselect('TroubleshootingFAQButton');
    deselect('AllFAQButton');
  };
  const highlightButton = id => {
    document.getElementById(id).className = classes.selectedButton;
  };
  const handleUserEvent = useCallback(event => {
    publishAnonEvent(event);
  }, []);
  const reportSelection = useCallback(
    category => {
      const event = {
        eventType: 'ButtonPressed',
        button: category,
        screen: 'MarketingLandingPage',
      };
      handleUserEvent(event);
    },
    [handleUserEvent]
  );
  return (
    <div className={classes.container}>
      <div className={classes.anchor} id="faq"></div>
      <div className={classes.title}>Answers to our most frequently asked questions</div>
      <div container className={classes.buttonContainer}>
        <button
          id="GeneralFAQButton"
          className={classes.selectedButton}
          onClick={() => select('GeneralFAQ')}
        >
          General
        </button>
        <button
          id="BackingUpFAQButton"
          className={classes.button}
          onClick={() => select('BackingUpFAQ')}
        >
          Backing Up
        </button>
        <button
          id="AccountPlusSecurityFAQButton"
          className={classes.button}
          onClick={() => select('AccountPlusSecurityFAQ')}
        >
          Account + Security
        </button>
        <button
          id="TroubleshootingFAQButton"
          className={classes.button}
          onClick={() => select('TroubleshootingFAQ')}
        >
          Troubleshooting
        </button>
        <button
          id="AllFAQButton"
          className={classes.button}
          onClick={() => select('AllFAQ')}
        >
          All
        </button>
      </div>
      <div className={classes.faqContainer}>
        <div id="GeneralFAQ">
          {categorySelected === 'GeneralFAQ' ? (
            <div>
              <div className={classes.faqTitle}>General</div>
              <Grid container className={classes.root}>
                {GeneralQuestions.map((item, index) => (
                  <Grid item xs={12} sm={12} md={6} key={index}>
                    <FaqExpansionPanel
                      index={index}
                      summary={item.summary}
                      details={item.details}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            false
          )}
        </div>
        <div id="BackingUpFAQ">
          {categorySelected === 'BackingUpFAQ' ? (
            <div>
              <div className={classes.faqTitle}>Backing Up</div>
              <Grid container className={classes.root}>
                {BackingUpQuestions.map((item, index) => (
                  <Grid item xs={12} sm={12} md={6} key={index}>
                    <FaqExpansionPanel
                      index={index}
                      summary={item.summary}
                      details={item.details}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            false
          )}
        </div>
        <div id="AccountPlusSecurityFAQ">
          {categorySelected === 'AccountPlusSecurityFAQ' ? (
            <div>
              <div className={classes.faqTitle}>Account + Security</div>
              <Grid container className={classes.root}>
                {AccountPlusSecurityQuestions.map((item, index) => (
                  <Grid item xs={12} sm={12} md={6} key={index}>
                    <FaqExpansionPanel
                      index={index}
                      summary={item.summary}
                      details={item.details}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            false
          )}
        </div>
        <div id="TroubleshootingFAQ">
          {categorySelected === 'TroubleshootingFAQ' ? (
            <div>
              <div className={classes.faqTitle}>Troubleshooting</div>
              <Grid container className={classes.root}>
                {TroubleshootingQuestions.map((item, index) => (
                  <Grid item xs={12} sm={12} md={6} key={index}>
                    <FaqExpansionPanel
                      index={index}
                      summary={item.summary}
                      details={item.details}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            false
          )}
        </div>
        <div id="AllFAQ">
          {categorySelected === 'AllFAQ' ? (
            <div>
              <div className={classes.faqTitle}>All</div>
              <Grid container className={classes.root}>
                {allQuestions.map((item, index) => (
                  <Grid item xs={12} sm={12} md={6} key={index}>
                    <FaqExpansionPanel
                      index={index}
                      summary={item.summary}
                      details={item.details}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    </div>
  );
};
const FaqExpansionPanel = withStyles(theme => ({
  root: {
    borderTop: '1px solid #D5D6DA',
    backgroundColor: 'transparent',
    margin: '0 12px',
    '&:last-child': {
      borderRadius: 0,
    },
    '&:first-child': {
      borderRadius: 0,
    },
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },
  expandIcon: {
    marginRight: '-28px',
  },
  summary: {
    minHeight: 85,
    backgroundColor: '#FFFFFF',
  },
  heading: {
    fontFamily: 'ApercuLightPro',
    marginLeft: '-18px',
    fontSize: '24px',
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
  body: {
    fontFamily: 'ApercuLightPro',
    fontSize: '1.15rem',
    backgroundColor: '#FFFFFF',
  },
}))(({ index, summary, details, classes }) => {
  const [expanded, setExpanded] = useState(allQuestions[index].autoOpen);
  const handleChange = useCallback(
    (event, expanded) => {
      setExpanded(expanded);
    },
    [setExpanded]
  );
  return (
    <ExpansionPanel
      elevation={0}
      expanded={expanded}
      onChange={handleChange}
      className={classes.root}
    >
      <ExpansionPanelSummary
        className={classes.summary}
        expandIcon={<ExpandMoreIcon stroke="#8223D2" />}
        classes={{
          expandIcon: classes.expandIcon,
        }}
      >
        <Typography className={classes.heading}>{summary}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography className={classes.body}>{details}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
});
const styles = theme => ({
  root: {
    marginTop: 40,
  },
  title: {
    fontFamily: 'ApercuLightPro',
    fontSize: '40px',
    textAlign: 'center',
    margin: '0px 24px 0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px',
      lineHeight: '41px',
    },
  },
  container: {
    background: '#FFFFFF',
  },
  buttonContainer: {
    marginTop: '32px',
    marginBottom: '32px',
    textAlign: 'center',
  },
  button: {
    color: 'black',
    cursor: 'pointer',
    margin: '2px',
    height: '32px',
    minWidth: '42px',
    fontFamily: 'ApercuWeb',
    background: '#FFFFFF',
    border: '2px solid #A5AAAF',
    borderRadius: '9999px',
    fontSize: '16px',
  },
  selectedButton: {
    cursor: 'pointer',
    margin: '2px',
    height: '32px',
    minWidth: '42px',
    fontFamily: 'ApercuWeb',
    background: '#FFFFFF',
    border: '2px solid #8223D2',
    color: '#8223D2',
    borderRadius: '9999px',
    fontSize: '16px',
  },
  faqTitle: {
    fontFamily: 'ApercuLightPro',
    marginLeft: '14px',
    fontSize: '32px',
  },
  faqContainer: {
    fontFamily: 'ApercuLightPro',
    background: '#FFFFFF',
    margin: 'auto',
    paddingBottom: '100px',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
});
export default withStyles(styles)(Faq);
