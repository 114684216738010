import React from 'react';

import { Paper, Typography, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { MobileView, BrowserView } from 'react-device-detect';

import appStore from '../assets/appstore.png';
import playStore from '../assets/playstore.png';
import qr from '../assets/identifyMeQr.png';

const isIOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

const isAndroid = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('android') > -1;
};

const DownloadApp = ({ classes, text }) => {
  return (
    <Paper square className={classes.root}>
      <div className={classes.qrContainer}>
        <Typography variant="body1" className={[classes.qrTitle].join(' ')}>
          {text || 'Download the app to get started'}
        </Typography>
        <img src={qr} className={classes.qrCode} alt="Q R Code" />
        <div className={classes.qrDesc}>
          Scan the code with your camera to get a download link
        </div>
        <div className={classes.downloadAppContainer}>
          <div className={classes.anchor} id="download"></div>
          <BrowserView>
            <Link target="blank" href={process.env.REACT_APP_DOWNLOADAPPSTORE_LINK}>
              <img src={appStore} className={classes.downloadLogo} alt="app store" />
            </Link>
            <Link target="blank" href={process.env.REACT_APP_DOWNLOADPLAYSTORE_LINK}>
              <img src={playStore} className={classes.downloadLogo} alt="play store" />
            </Link>
          </BrowserView>
          <MobileView>
            {isIOS() && (
              <Link target="blank" href={process.env.REACT_APP_DOWNLOADAPPSTORE_LINK}>
                <img
                  src={appStore}
                  className={classes.downloadLogo}
                  alt="Download at App Store"
                />
              </Link>
            )}
            {isAndroid() && (
              <Link target="blank" href={process.env.REACT_APP_DOWNLOADPLAYSTORE_LINK}>
                <img
                  src={playStore}
                  className={classes.downloadLogo}
                  alt="Download at Play Store"
                />
              </Link>
            )}
          </MobileView>
        </div>
      </div>
    </Paper>
  );
};

const breakpoint = 'sm';
export const styles = theme => ({
  root: {
    width: '100%',
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(255, 255, 255, 0) 45.45%)',
    padding: '100px 0px 40px 0px',
  },
  qrContainer: {
    textAlign: 'center',
    width: '100%',
  },
  qrTitle: {
    fontFamily: 'ApercuLightPro',
    fontSize: '2.5rem',
    lineHeight: '2.875rem',
    textAlign: 'center',
    margin: '0px 24px 0px',
    [theme.breakpoints.down(breakpoint)]: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
  },
  qrCode: {
    height: '200px',
    width: '200px',
    padding: '24px',
    [theme.breakpoints.down(breakpoint)]: {
      display: 'none',
    },
  },
  qrDesc: {
    fontFamily: 'ApercuLightPro',
    fontSize: '20px',
    width: '340px',
    margin: '0 auto',
    [theme.breakpoints.down(breakpoint)]: {
      display: 'none',
    },
  },
  downloadAppContainer: {
    marginTop: 32,
    textAlign: 'center',
  },
  downloadLogo: {
    width: 150,
    margin: '0 8px',
  },
});

export default withStyles(styles)(DownloadApp);
