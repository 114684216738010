import { FullStory as FS, init as initFullStory } from '@fullstory/browser';

// import { generateDeviceID } from './clientInfoService';

initFullStory({ orgId: 'ZVSN6' });

export const initiateFullsory = async () => {
  // const uniqueId = localStorage.getItem('uniqueId')
  //   ? localStorage.getItem('uniqueId')
  //   : await generateDeviceID();

  if (process.env.REACT_APP_ENABLE_FULLSTORY_EVENTS) {
    // if (uniqueId) {
    //   FS('setIdentity', {
    //     uid: uniqueId,
    //   });
    // } else {
    FS('setIdentity', { anonymous: true });
    // }
  }
};
