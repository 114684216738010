import React from 'react';

import { Paper, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import multipleDevicesIcon from '../assets/multiple-devices.svg';
import securityIcon from '../assets/security-lock.svg';
import cloudBackupIcon from '../assets/cloud-backup.svg';

const data = [
  {
    logo: cloudBackupIcon,
    title: 'Photo and video cloud backup',
    desc: 'Asurion subscribers get unlimited storage. Free users get 5GB of storage.',
  },
  {
    logo: multipleDevicesIcon,
    title: 'Access anywhere, anytime',
    desc: 'View photos on your desktop, tablet, or phone (iOS and Android).',
  },
  {
    logo: securityIcon,
    title: 'Bank-level encryption and security',
    desc: "Only you can see your photos. They'll always stay private.",
  },
];

const InfoBar = ({ classes }) => {
  return (
    <Paper elevation={0} square className={classes.root}>
      <Grid container spacing={16}>
        {data.map(item => (
          <Grid item xs={12} sm={12} md={4} className={classes.item}>
            <>
              <Paper elevation={0} square className={classes.container}>
                <Paper elevation={0} square className={classes.logoContainer}>
                  <img src={item.logo} alt="" className={classes.logo} />
                </Paper>
                <Typography variant="h5" className={classes.title}>
                  {item.title}
                </Typography>
                <Typography variant="body1" className={classes.desc}>
                  {item.desc}
                </Typography>
              </Paper>
            </>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

const breakpoint = 'sm';

export const styles = theme => ({
  root: {
    width: '100%',
    margin: '24px 24px 48px',
  },
  item: {
    padding: '8px',
    [theme.breakpoints.down(breakpoint)]: {
      padding: '8px 8px 48px 8px !important',
    },
  },
  logo: { maxWidth: '60px' },
  logoContainer: {
    backgroundColor: '#F0F0F5',
    borderRadius: '50%',
    height: '128px',
    width: '128px',
    justifyContent: 'center',
    display: 'flex',
    margin: '24px',
  },
  container: {
    minHeight: 300,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    fontFamily: 'ApercuLightPro',
    fontSize: '24px',
  },
  desc: {
    fontFamily: 'ApercuLightPro',
    margin: '16px',
    fontSize: '16px',
  },
});

export default withStyles(styles)(InfoBar);
