import brand from '../core/config/brand';

export const authToken = async (genAuthURL, uniqueId) => {
  let authTkn;
  let authExpiration;
  if (
    localStorage.authTkn &&
    localStorage.authExpiration &&
    localStorage.authTkn != null &&
    localStorage.authExpiration - 5000 > new Date().getTime()
  ) {
    return localStorage.authTkn;
  } else {
    const response = await fetch(genAuthURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        CarrierId: brand.carrierId,
        uniqueId: uniqueId,
        ApiKey: 'F4GFAHASFSA6DHEZR',
      },
    });
    const myJson = await response.json();
    authTkn = myJson.authToken;
    authExpiration = myJson.expiration;
    localStorage.setItem('authTkn', authTkn);
    localStorage.setItem('authExpiration', authExpiration);
    return authTkn;
  }
};

export default authToken;
