import React from 'react';
import { Grid, Paper, Hidden, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import explorePhotos from './assets/Explore-2x.png';
import optimizePhotos from './assets/Optimize-2x.png';
import editPhotos from './assets/iPad-mockup2x.png';

import asurionPhotosLogo from './assets/logo-lockup.svg';
import CheckIcon from './assets/CheckIcon';

const data = [
  {
    title: 'Easily find your favorite photos',
    points: [
      'Search for specific moments',
      'Tag family and friends',
      'Group photos by events, locations, and more',
    ],
    imageInfo: {
      image: explorePhotos,
      position: 'right',
    },
  },
  {
    title: 'Organize and decluttering made simple',
    points: [
      'Reduce clutter to focus on your favorite memories',
      'Clean up unwanted photos with built in tools',
      'Make the most of your device storage',
    ],
    imageInfo: {
      image: optimizePhotos,
      position: 'left',
    },
  },
  {
    title: 'Edit your photos and videos all in one place',
    points: [
      'Resize, crop, filter, frame, sticker, and more',
      'Create your own masterpieces',
      'Share with friends and family',
    ],
    imageInfo: {
      image: editPhotos,
      position: 'right',
    },
  },
];

const ProductDetails = ({ classes }) => {
  return (
    <Grid container>
      <div className={classes.logoContainer}>
        <img src={asurionPhotosLogo} className={classes.logo} alt="Asurion Photos Logo" />
        <div className={classes.logoDesc}>There's more to explore in the app</div>
      </div>

      {data.map((item, i) => (
        <Paper elevation={0} square className={classes.container} key={i}>
          {i % 2 !== 0 ? (
            <>
              <Paper
                elevation={0}
                square
                className={[classes.image, classes.content].join(' ')}
                style={{ 'background-image': `url(${item.imageInfo.image})` }}
              ></Paper>
              <Paper elevation={0} square className={classes.content}>
                <ProductDetailsInfo data={item} />
              </Paper>
            </>
          ) : (
            <>
              <Hidden mdUp>
                <Paper
                  elevation={0}
                  square
                  className={[classes.image, classes.content].join(' ')}
                  style={{ 'background-image': `url(${item.imageInfo.image})` }}
                ></Paper>
              </Hidden>
              <Paper elevation={0} square className={classes.content}>
                <ProductDetailsInfo data={item} />
              </Paper>
              <Hidden smDown>
                <Paper
                  elevation={0}
                  square
                  className={[classes.image, classes.content].join(' ')}
                  style={{ 'background-image': `url(${item.imageInfo.image})` }}
                ></Paper>
              </Hidden>
            </>
          )}
        </Paper>
      ))}
    </Grid>
  );
};

export const styles = theme => ({
  root: {
    width: '100%',
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(255, 255, 255, 0) 45.45%)',
  },
  logoContainer: {
    margin: '52px 0px 80px 0px',
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '0px 24px 24px',
    },
  },
  logo: {
    padding: '20px',
  },
  logoDesc: {
    fontFamily: 'ApercuLightPro',
    fontSize: '24px',
    margin: '0 auto',
  },
  container: {
    backgroundColor: 'transparent',
    width: '100%',
    margin: '0px 40px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      margin: '40px 24px 0px',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '50%',
    margin: '0px 20px 0px 20px',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      alignItems: 'flex-start',
      margin: '0px 0px 20px 0px',
    },
  },
  image: {
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: 500,
    [theme.breakpoints.down('md')]: {
      height: 400,
    },
    [theme.breakpoints.down('sm')]: {
      height: 240,
    },
  },
  title: {
    fontFamily: 'ApercuLightPro',
    fontSize: '2.5rem',
    lineHeight: '2.875rem',
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
  },
  text: {
    fontFamily: 'ApercuLightPro',
    fontSize: '1.25rem',
    lineHeight: '1.6874rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
  },
  checkIcon: {
    stroke: '#8223D2',
    marginRight: 18,
  },
  productContent: {
    maxWidth: 500,
    backgroundColor: 'transparent',
  },
  productText: {
    margin: '8px 0',
  },
});

const ProductDetailsInfo = withStyles(styles)(({ classes, data }) => {
  return (
    <Paper className={classes.productContent} elevation={0} square>
      <Typography className={classes.title} component="div" variant="body1">
        {data.title}
      </Typography>
      {data.points.map((desc, i) => (
        <Grid className={classes.productText} container wrap="nowrap" key={i}>
          <Grid item>
            <CheckIcon className={classes.checkIcon} />
          </Grid>
          <Grid item>
            <Typography className={classes.text} component="span" variant="body1">
              {desc}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Paper>
  );
});

export default withStyles(styles)(ProductDetails);
